import { Routes, Route, Outlet, Link } from "react-router-dom";
import Home from "./pages/home";
import Footer from "./components/layout/footer";
import Contact from "./pages/contact";
import { FaPhone } from "react-icons/fa";
import PrivacyPolicy from "./pages/privacy-policy";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        {/* <Route path="about" element={<About />} /> */}
        {/* <Route path="dashboard" element={<Dashboard />} /> */}
        <Route path="contact" element={<Contact />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
}

function Layout() {
  return (
    <>
      {/* Navbar */}
      <nav className="bg-secondary text-white pt-6 pb-6 px-4">
        <ul className="flex justify-between">
          <li className="my-auto">
            <Link to="/" className="hover:underline">
              <img src="/WWR_Logo_2.png" alt="logo" className="w-48" />
            </Link>
          </li>
          <li className="flex gap-4 my-auto">
            <a href="tel:918-742-8482" className="hover:underline bg-primary py-3 px-4 rounded-full my-auto font-semibold">
              <FaPhone />
            </a>
            <Link to="/contact" className="hidden md:inline hover:underline bg-primary py-3 px-4 rounded-full my-auto font-semibold text-center" >
              GET A FREE ESTIMATE
            </Link>
          </li>
        </ul>
      </nav>
      {/* Outlet where the pages will be rendered */}
        <Outlet />
      <Footer />
    </>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}